import React from "react"
import Wrapper from "../components/wrapper";
import { StaticImage } from 'gatsby-plugin-image';
import SectionTitle from "../components/sectionTitle";

const About = ({location}) => {
    return (
        <Wrapper
            customTitle={"About Appleseeds Web Design Company in Kuala Lumpur"} 
            customDescription={"Appleseeds is a website & mobile app design and development company in Kuala Lumpur. We provide design & development service to international brands."} 
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"} 
            // customOgImage={""}
            slug={location.pathname}
            >
            <section className="bg-about-people">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={true}
                        title={['Why we do ', <br className="block lg:hidden"/>, 'what we do']}
                        minititle=""
                        type="h1"
                        invert={true}
                        childrenClassName="md:w-2/3"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            We feel driven knowing what we do will bring positive change to people’s lives. It’s about the spark of exhilaration when the right people come together to bring life to electrifying ideas.
                        </p>
                    </SectionTitle>
                </div>
            </section>

            <section className="bg-goal">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName="md:ml-auto md:w-1/2"
                        alignCenter={false}
                        title={["Our goal has always been ", "to produce the best work."]}
                        minititle="Our goal"
                        type="h3"
                        invert={true}
                        childrenClassName=""
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                        Since inception, we have been serving clients from international hotels and newspaper portals to a music studio and an organic farm. To us, it’s never about the size of the project, it’s about finding the right collaboration so that the best work can be produced in the best way.
                        </p>
                    </SectionTitle>
                </div>
            </section>

            <section className="bg-values">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={false}
                        title={['Honesty, integrity ', <br/>, 'and sustainability.']}
                        minititle="Our values"
                        type="h3"
                        invert={false}
                        childrenClassName="md:w-3/4"
                        buttonText="Check out our portfolio"
                        buttonType="btn-filled"
                        buttonLink="/portfolio/"
                        >
                        <p>
                            We believe in doing right by our clients. Over the years, these values have not only helped us flourish our partnerships, but also paved the path for project successions.
                        </p>
                    </SectionTitle>
                </div>
            </section>

            {/* <section className="team py-14 md:py-28">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={true}
                        title="We eat passion for breakfast"
                        minititle="Our team"
                        type="h3"
                        invert={false}
                        childrenClassName="md:w-3/4"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            Often, people wonder how a lean team like ours can produce work of such calibre. And all we can say is, passion goes a long way.
                        </p>
                    </SectionTitle>
                    <div className="mt-10">
                        <div className="flex justify-center space-x-8 mb-10">
                            <div className="w-1/2 md:w-1/3">
                                <div className="people">
                                    <StaticImage
                                        src="../images/people/people-dennis.jpg"
                                        alt="Dennis Lee, Managing Director, Appleseeds"
                                        layout="fullWidth"
                                        // width={400}
                                        // height={570}
                                    />
                                    <h4>Dennis Lee</h4>
                                    <p>Managing Director</p>
                                </div>
                            </div>
                            <div className="w-1/2 md:w-1/3">
                                <div className="people">
                                    <StaticImage
                                        src="../images/people/people-kevin.jpg"
                                        alt="Kevin Teh, Chief Executive Officer, Appleseeds"
                                        layout="fullWidth"
                                        // width={400}
                                        // height={570}
                                    />
                                    <h4>Kevin Teh</h4>
                                    <p>Chief Executive Officer</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mb-10">
                            <div className="col-span-1">
                                <div className="people">
                                    <StaticImage
                                        src="../images/people/people-man.jpg"
                                        alt="Manmohanjit Singh, Lead Developer, Appleseeds"
                                        layout="fullWidth"
                                        // width={400}
                                        // height={570}
                                    />
                                    <h4>Manmohanjit Singh</h4>
                                    <p>Lead Developer</p>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="people">
                                    <StaticImage
                                        src="../images/people/people-lucas.jpg"
                                        alt="Lucas Lim, Product Developer, Appleseeds"
                                        layout="fullWidth"
                                        // width={400}
                                        // height={570}
                                    />
                                    <h4>Lucas Lim</h4>
                                    <p>Product Developer</p>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="people">
                                    <StaticImage
                                        src="../images/people/people-lester.jpg"
                                        alt="Lester Tan, UI/UX Designer, Appleseeds"
                                        layout="fullWidth"
                                        // width={400}
                                        // height={570}
                                    />
                                    <h4>Lester Tan</h4>
                                    <p>UI/UX Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="bg-brand-dark-blue py-14 md:py-28">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={false}
                        title="We are hiring!"
                        minititle=""
                        type="h2"
                        invert={true}
                        childrenClassName="md:w-3/4"
                        buttonText="Join our team"
                        buttonType="btn-light"
                        buttonLink="/jobs/"
                        >
                        <p>
                            If you have as much passion in web design and development as we do, come and join us to create great work together. 
                        </p>
                    </SectionTitle>
                </div>
            </section>
        </Wrapper>
    );
};

export default About;